export const environment = {
    production: false,
    baseUrl: 'https://test.crm.mojosoft.work/ws',
    userId: 'crm',
    password: '1234',
    appKey:"e568458e9121478125d076b344e26c9e",
    assetsUrl:"https://test.crm.mojosoft.work/resources",
    version:"TEST 0.0.1",
    buildNo:202501272125,
  };
  
